
import moment from "moment";
import fileDownload from "../../mixins/file-download";
import ConfirmModal from "../confirm-modal";
import modals from "../../mixins/modals";
import ContactName from "../contacts/contact-name.vue";
import DownloadSelectColumnsModal from "../download-select-columns-modal.vue";
import InterestListModal from "./interest-list-item-modal";
import InterestListBulkOperationModal from "./interest-list-bulk-operation-modal";

export default {
    name: "InterestList",
    components: {
        DownloadSelectColumnsModal,
        ContactName,
        InterestListBulkOperationModal,
        ConfirmModal,
        InterestListModal,
    },
    mixins: [modals, fileDownload],
    props: {
        company: {
            type: Object,
            default: null,
        },
        contact: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            updated: new Date(),
            interestListItemIdToDelete: null,
            interestListItemKey: "interestListItemKey",
            interestListToEdit: null,
            interestListItemIdToEdit: null,
            selectedRows: [],
            preSelectedRows: [],
            preSelectedRowIds: [],
            pageChanged: false,
            ajaxRows: null,
            ajaxAll: 0,
            bulkTriggered: false,
            bulkUpdated: moment().unix(),
            bulkModal: null,
            mode: "filtered",
            interestListModalTitle: false,
            downloadQuery: null,
            sortOrder: null
        }
    },
    computed: {
        excelUrl() {
            return '/api/company_sheets/generate_pdf/' + this.company.id;
        },
        config() {
            return {
                // Add sorting and filtering (by status)
                columns: [
                    {
                        headline: "Company",
                        hidden: this.company !== null,
                    },
                    {
                        headline: "Status",
                        sort: true,
                    },
                    {
                        headline: "Contact",
                        sort: true,
                        hidden: this.company === null,
                    },
                    {
                        headline: "Contact Company",
                        sort: true,
                        hidden: this.company === null,
                    },
                    {
                        headline: "Contact Type",
                        sort: false,
                        breakpoint: "sm",
                        hidden: this.company === null,
                    },
                    {
                        headline: "Comment",
                        breakpoint: "md",
                    },
                    {
                        headline: "Last Status Change",
                        sort: true,
                        breakpoint: "lg",
                    },
                    {
                        headline: "User",
                        breakpoint: "lg",
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end"
                    }
                ],
                select: true,
                selectPosition: "pre",
                selectAll: true,
                prettySelect: true,
                ajaxUrl: this.company ? `/api/interest_list/list?company_id=${this.company.id}` : `/api/interest_list/list?contact_id=${this.contact.id}`,
                pagination: 10,
                filter: false,
                search: true,
            };
        },
        bulkFilters() {
            switch (this.mode) {
                case "selected":
                    return {
                        ids: structuredClone(this.preSelectedRowIds),
                    }
                case "page":
                    return {
                        ids: this.ajaxRows.map(function (row) {
                            return row.interest_list_item_id;
                        })
                    }
                case "filtered":
                default:
                    if (this.company) {
                        return {companies: [this.company.id]};
                    } else if (this.contact) {
                        return {contacts: [this.contact.id]};
                    } else {
                        console.error("This will run a bulk operation with everything. You want this?");
                        return {};
                    }
            }
        },
        filters() {
            return {};
        },
    },
    watch: {
        selectedRows(rows) {
            if (!this.pageChanged && this.ajaxRows) {

                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedRowIds.includes(rows[i].interest_list_item_id)) {
                        this.preSelectedRowIds.push(rows[i].interest_list_item_id);
                    }
                }

                for (let j = 0; j < this.ajaxRows.length; j++) {
                    const id = this.ajaxRows[j].interest_list_item_id;

                    const index = this.preSelectedRowIds.indexOf(id);

                    if (!rows.map(r => r.interest_list_item_id).includes(id) && index !== -1) {
                        this.preSelectedRowIds.splice(index, 1);
                    }
                }
            }
        },
    },

    methods: {
        contactsAdded() {
            this.closeModal(this.$refs.interestListItemModal);
        },
        onPageChange() {
            this.pageChanged = true;
        },
        sortUpdate(order) {
            this.sortOrder = order;
            this.pageChanged = true;
        },
        onRowsUpdated(data) {
            this.tableLoaded = true;
            this.pageChanged = false;
            if (data && data.rows && data.rows.length) {
                this.preSelectedRows = JSON.parse(JSON.stringify(this.preSelectedRowIds)).map(id => {
                    return {
                        key: "interest_list_item_id",
                        value: id
                    }
                });
            }

            this.ajaxRows = data.rows;
            this.ajaxAll = data.all;

        },
        openInterestListModal() {
            this.interestListToEdit = null
            this.interestListItemKey = this.generateUUID();
            this.interestListModalTitle = true;
            this.$nextTick(() => {

                this.modal = this.openModal(this.$refs.interestListItemModal);

            })
        },
        deleteInterestListItem(id) {
            this.interestListItemIdToDelete = id;
            this.modal = this.openModal(this.$refs.deleteInterestListItem);
        },
        doDeleteInterestListItem() {
            this.$axios.delete(`/api/interest_list_items/${this.interestListItemIdToDelete}`)
                .then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Interest List Item deleted",
                        message: "The interest list item has been deleted successfully.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                })
                .finally(() => {
                    this.interestListItemIdToDelete = null;
                    this.modal = null;
                    this.updated = new Date();
                });
        },
        cancelInterestListItemDeletion() {
            this.modal = null;
        },
        onUpdated() {
            this.updated = new Date();
            this.closeInterestListModal();
        },
        onDelete() {
            this.updated = new Date();
            this.closeInterestListModal(this.modal);
        },
        closeInterestListModal() {
            this.interestListToEdit = null
            if (this.modal) {
                this.closeModal(this.modal);
            }
        },
        editInterestListItem(id) {
            this.$axios.get(`/api/interest_list_items/${id}`)
                .then((response) => {
                    this.interestListToEdit = response.data;
                    this.interestListModalTitle = false;
                    this.interestListItemKey = this.generateUUID();
                }).finally(() => {

                    this.modal = this.openModal(this.$refs.interestListItemModal);

                });
        },
        clearPreSelection() {
            this.preSelectedRowIds = [];
            this.preSelectedRows = [];
        },
        onBulkSubmitted(doReload = false) {
            this.updated = new Date();
            this.closeModal(this.bulkModal);
        },
        openBulkOperationModal(mode) {
            this.mode = mode;
            this.bulkUpdated = moment().unix();
            this.bulkTriggered = true;

            this.$nextTick(() => {
                this.bulkModal = this.openModal(this.$refs.interestListBulkModal)
            })
        },
        downloadBulkContacts(filters) {
            this.closeModal(this.bulkModal);
            this.downloadQuery = {
                filters
            };
            this.openModal(this.$refs.downloadContactsModal);
        },
        downloadInterestList() {
            const filters = this.company ? {
                companies: this.company.id
            } : {
                contacts: this.contact.id
            }
            const query = {
                // search: null,
                filters,
                sort: this.sortOrder,
            }
            this.saveQueryAndExport(
                query,
                this.company ? '/api/interest_list/excel_export/' + this.company.id + '/' + null
                : '/api/interest_list/excel_export/' + null + '/' + this.contact.id);
        },
    },
}
