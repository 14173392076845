import { render, staticRenderFns } from "./files-card.vue?vue&type=template&id=696116d6&scoped=true"
import script from "./files-card.vue?vue&type=script&lang=js"
export * from "./files-card.vue?vue&type=script&lang=js"
import style0 from "./files-card.vue?vue&type=style&index=0&id=696116d6&prod&scoped=true&lang=scss"
import style1 from "./files-card.vue?vue&type=style&index=1&id=696116d6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "696116d6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src3357764168/src/web/components/loading-spinner.vue').default,SkeletonFiles: require('/codebuild/output/src3357764168/src/web/components/skeleton/skeleton-files.vue').default,TableActions: require('/codebuild/output/src3357764168/src/web/components/table-actions.vue').default,ConfirmModal: require('/codebuild/output/src3357764168/src/web/components/confirm-modal.vue').default})
