
import moment from 'moment';
import fileDownload from '../../mixins/file-download';
import LoadingSpinner from '../loading-spinner';

const DATE_FORMAT = 'YYYY-MM-DD';

export default {
    name: 'InvolvementReportModal',
    components: {
        LoadingSpinner,
    },
    mixins: [fileDownload],
    props: {
        company: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            listAllParticipants: true,
            endDate: null,
            generationIsInProgress: false,
            startDate: null,
            displayEndDate: null,
        };
    },
    computed: {
        canGenerate() {
            return !this.generationIsInProgress && this.startDate !== null && this.endDate !== null;
        },
    },
    methods: {
        generateReport(fileType) {
            this.generationIsInProgress = true;
            this.$axios
                .post(
                    '/api/companies/involvement_report',
                    {
                        companyId: this.company.id,
                        listAllParticipants: this.listAllParticipants,
                        endDate: this.endDate,
                        startDate: this.startDate,
                        displayEndDate: this.displayEndDate,
                        fileType,
                    },
                    {
                        responseType: 'blob',
                    }
                )
                .then((response) => {
                    this.downloadBlob(response.data, `${this.company.name} Involvement Report.${fileType}`);
                })
                .finally(() => {
                    this.generationIsInProgress = false;
                });
        },
        setPeriod(years) {
            this.endDate = moment().add(3, 'months').format(DATE_FORMAT);
            this.displayEndDate = moment().format(DATE_FORMAT);
            this.startDate = moment().subtract(years, 'years').format(DATE_FORMAT);
        },
    },
};
