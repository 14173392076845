
import { mapGetters } from 'vuex';
import moment from 'moment';
import MD5 from 'crypto-js/md5';
import files from '../../mixins/files';
import optionButtonTooltips from '../../mixins/option-button-tooltip';
import LoadingSpinner from '../loading-spinner';
import ConfirmModal from '../confirm-modal';
import TableActions from '../table-actions';
import FileIcon from '../helpers/file-icon';
import modals from '../../mixins/modals';
import SkeletonFiles from '../skeleton/skeleton-files';
import FileNamingModal from './file-naming-modal';
import FileCategoryModal from './file-category-modal';
import SummarizeModal from './file/summarize-modal';

export default {
    name: 'FilesCard',
    components: {
        SkeletonFiles,
        FileCategoryModal,
        FileIcon,
        TableActions,
        ConfirmModal,
        FileNamingModal,
        LoadingSpinner,
        SummarizeModal,
    },
    mixins: [files, optionButtonTooltips, modals],
    props: {
        company: {
            type: Object,
            required: false,
            default: null,
        },
        event: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            filelist: [], // Store our uploaded files
            highlightDropArea: false,
            file: {},
            isUploading: false,
            config: {
                columns: [
                    {
                        headline: 'Icon',
                    },
                    {
                        headline: 'File',
                    },
                    {
                        headline: 'Actions',
                        hideHeadlineBreakpoint: 'all',
                        align: 'end',
                    },
                ],
                pagination: 5,
                select: true,
                selectAll: true,
                selectAllRows: true,
                prettySelect: true,
            },
            selectedRows: [],
            tableUpdate: null,
            modal: null,
            tableLoading: false,
            rows: [],
            rowsUpdated: moment().unix(),
            fileToDelete: null,
            fileInputKey: moment().unix(),
            fileToCategorize: {},
            categoryKey: moment().unix(),
            fileLoading: false,
            categoriesLoading: false,
            fileCategories: [],
            filters: { categories: {} },
            initial: true,
            fileToPatch: {},
            activeFile: null,
        };
    },
    computed: {
        ...mapGetters({
            globalDrag: 'app/globalDrag',
        }),
        acceptedTypes() {
            return '.pdf,.jpg,.jpeg,.png,.zip,.xls,.xlsx,.svg,.docx,.doc,.ppt,.pptx';
        },
        acceptedTypesArray() {
            return this.acceptedTypes.split(',');
        },
        isCompanyFile() {
            return this.company != null;
        },
        isEventFile() {
            return this.event != null;
        },
        fileSpecificURLPath() {
            if (this.isCompanyFile) {
                return 'company_file';
            } else if (this.isEventFile) {
                return 'event_file';
            } else {
                // eslint-disable-next-line no-throw-literal
                throw 'Neither company nor event file!';
            }
        },
        isAllowedToUpload() {
            if (this.isCompanyFile) {
                return true;
            } else if (this.isEventFile) {
                return this.isUserWithRoleCorporateAccess;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw 'Neither company nor event file!';
            }
        },
    },
    watch: {
        rows() {
            this.$nextTick(() => {
                this.rowsUpdated = moment().unix();
            });
        },
        filters: {
            handler(val, old) {
                if (old.categories && Object.keys(old.categories).length) {
                    this.loadRows();
                }
            },
            deep: true,
        },
        fileCategories(val) {
            this.filters.categories = {};
            val.forEach((c) => {
                this.$set(this.filters.categories, c.id, true);
            });
        },
        rowsUpdated() {
            setTimeout(() => this.initializeOptionButtonTooltips('rows updated'), 250);
        },
    },
    created() {
        this.tableLoading = true;
        const promises = [this.loadFileTemplate(), this.loadCategories()];

        Promise.all(promises).then(() => {
            this.loadRows(false).then(() => {
                this.tableLoading = false;
            });
        });
    },
    methods: {
        setIncludeInMail(file) {
            this.$axios
                .patch(
                    `/api/${this.fileSpecificURLPath}s/${file.id}`,
                    { includeInMail: !file.includeInMail },
                    {
                        headers: {
                            'Content-Type': 'application/merge-patch+json',
                        },
                    }
                )
                .then(() => {
                    this.loadRows();
                    if (!file.includeInMail) {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: 'success',
                            title: 'Included',
                            message: `File included in confirmation mail`,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    } else if (file.includeInMail) {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: 'success',
                            title: 'Excluded',
                            message: `File excluded from confirmation mail`,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    }
                });
        },
        onPageUpdate() {
            setTimeout(() => this.initializeOptionButtonTooltips('page updated'), 250);
        },
        onChange() {
            if (!this.isAllowedToUpload) {
                console.warn('User not allowed to upload');
                return;
            }

            if (this.filelist.length === 0 && this.$refs.file.files.length > 0) {
                this.filelist = [...this.$refs.file.files];
            }

            const promises = [];
            for (let i = 0; i < this.filelist.length; i++) {
                const currentFile = this.filelist[i];

                const promise = new Promise((resolve) => {
                    // Prepare File
                    const reader = new FileReader();
                    reader.readAsDataURL(currentFile);

                    // directory
                    reader.onerror = () => {
                        return resolve('error');
                    };

                    reader.onload = () => {
                        return resolve(currentFile);
                    };
                });
                promises.push(promise);
            }

            Promise.all(promises).then((responses) => {
                const validFiles = responses.filter((x) => x !== 'error');
                if (validFiles.length) {
                    this.filelist = validFiles;
                    this.showNameFilesDialog();
                }
            });
        },
        remove(i) {
            if (!this.isAllowedToUpload) {
                console.warn('User not allowed to remove');
                return;
            }

            this.filelist.splice(i, 1);
        },
        dragover(event) {
            event.preventDefault();

            if (!this.isAllowedToUpload) {
                console.warn('User not allowed to upload');
                return;
            }

            this.highlightDropArea = true;
        },
        dragleave(event) {
            // Clean up
            this.highlightDropArea = false;
        },
        drop(event) {
            event.preventDefault();

            if (!this.isAllowedToUpload) {
                console.warn('User not allowed to upload');
                return;
            }

            this.highlightDropArea = false;

            const items = event.dataTransfer.items;
            const promises = [];
            for (let i = 0; i < items.length; i++) {
                const item = items[i].webkitGetAsEntry();

                if (item) {
                    promises.push(this.addFileOrDirectory(item));
                }
            }
            Promise.all(promises).then(() => {
                this.onChange();
            });
        },
        addFileOrDirectory(item) {
            if (item.isDirectory) {
                const directoryReader = item.createReader();
                return new Promise((resolve) => {
                    directoryReader.readEntries((entries) => {
                        const promises = [];
                        entries.forEach((entry) => {
                            promises.push(this.addFileOrDirectory(entry));
                        });
                        Promise.all(promises).then(() => {
                            resolve();
                        });
                    });
                });
            } else {
                return new Promise((resolve) => {
                    item.file((file) => {
                        let allowed = true;
                        if (!this.acceptedTypesArray.includes('.' + file.name.split('.').pop())) {
                            const toastId = this.generateUUID();
                            this.addToast({
                                type: 'warning',
                                title: 'Filetype not allowed',
                                message: 'File ' + file.name + ' is not allowed.',
                                id: toastId,
                            });
                            this.$nextTick(() => {
                                this.toggleToast(toastId);
                            });
                            allowed = false;
                        }

                        if (allowed && file.name.charAt(0) !== '.') {
                            this.filelist.push(file);
                        }
                        resolve();
                    });
                });
            }
        },
        loadFileTemplate() {
            let url;
            if (this.isCompanyFile) {
                url = `/api/company_files/${this.company.id}/empty`;
            } else if (this.isEventFile) {
                url = `/api/event_files/${this.event.id}/empty`;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw 'Neither company nor event file!';
            }

            return this.$axios.get(url).then((response) => {
                this.file = response.data;
            });
        },
        uploadFile(fileNames) {
            this.isUploading = true;
            this.highlightDropArea = false;

            if (!this.isAllowedToUpload) {
                console.warn('User not allowed to upload');
                return;
            }

            this.closeModal(this.modal);

            const promises = [];

            // Upload all files, one post for each file
            for (let i = 0; i < this.filelist.length; i++) {
                const promise = new Promise((resolve) => {
                    // Prepare File
                    const file = structuredClone(this.file);
                    const currentFile = this.filelist[i];

                    const reader = new FileReader();
                    reader.readAsDataURL(currentFile);

                    // directory
                    reader.onerror = () => {
                        return resolve('error');
                    };

                    reader.onload = (e) => {
                        let presentFiles = [];
                        if (this.isCompanyFile) {
                            presentFiles = this.company.companyFiles;
                        } else if (this.isEventFile) {
                            presentFiles = this.event.eventFiles;
                        } else {
                            // eslint-disable-next-line no-throw-literal
                            throw 'Neither company nor event file!';
                        }

                        const fileChecksum = MD5(e.target.result).toString();

                        for (let i = 0; i < presentFiles.length; i++) {
                            if (presentFiles[i].fileChecksum === fileChecksum) {
                                const toastId = this.generateUUID();
                                this.addToast({
                                    type: 'warning',
                                    title: 'Exists',
                                    message: `File already exists, ignoring: ${currentFile.name}`,
                                    id: toastId,
                                });
                                this.$nextTick(() => {
                                    this.toggleToast(toastId);
                                });
                                return resolve('error');
                            }
                        }

                        if (reader.error && reader.error.name === 'NotFoundError') {
                            console.log('Reader Error:', reader.error);
                        }

                        file.files = {
                            fileData: {
                                extension: currentFile.name.split('.').pop(),
                                data: reader.result,
                                checksumField: 'fileChecksum',
                            },
                        };

                        const filenameKey = this.getFileIdentifier(currentFile);
                        file.title = fileNames[filenameKey].name;
                        file.filenameOriginal = fileNames[filenameKey].name;

                        if (this.isEventFile && 'includeInMail' in fileNames[filenameKey]) {
                            file.includeInMail = fileNames[filenameKey].includeInMail;
                        }
                        // FIXME IRI should be set properly when template is fetched from server
                        if (this.isCompanyFile && file.company.id > 0) {
                            file.company = '/api/companies/' + this.file.company.id;
                        } else if (this.isEventFile && file.event.id > 0) {
                            file.event = '/api/events/' + this.file.event.id;
                        }

                        if (file.user && file.user.id) {
                            file.user = '/api/users/' + file.user.id;
                        }

                        // Upload file
                        this.$axios
                            .post(`/api/${this.fileSpecificURLPath}s`, file)
                            .then(() => {
                                resolve('success');
                            })
                            .catch(() => {
                                resolve('error');
                            });
                    };
                });

                promises.push(promise);
            }

            Promise.all(promises)
                .then((responses) => {
                    if (responses.includes('success')) {
                        this.$nextTick(() => {
                            this.loadRows();
                        });
                        this.$emit('files-updated');
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: 'success',
                            title: 'Uploaded',
                            message: `Files uploaded`,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    }
                    this.resetFileUpload();
                })
                .finally(() => {
                    this.isUploading = false;
                });
        },
        resetFileUpload() {
            this.filelist = [];
            this.fileInputKey = moment().unix();
            this.loadFileTemplate();
        },
        deleteFile(id) {
            this.fileToDelete = id;
            this.modal = this.openModal(this.$refs.deleteFileModal);
        },

        doDeleteFile(id) {
            if (!this.isAllowedToUpload) {
                console.warn('User not allowed to delete');
                return;
            }

            this.$axios.delete(`/api/${this.fileSpecificURLPath}s/${id}`).finally(() => {
                this.fileToDelete = null;
                this.loadRows();
            });
        },
        cancelDeleteFile() {
            this.fileToDelete = null;
        },
        showNameFilesDialog() {
            this.modal = this.openModal(this.$refs.fileNamingModal);
        },
        downloadBulk() {
            const selectedIds = this.selectedRows.map((row) => row.id);
            const query = this.buildQueryString({
                ids: selectedIds,
            });

            const url = `${this.$axios.defaults.baseURL}/api/${this.fileSpecificURLPath}s/bulk_download?${query}`;

            this.loadRows();
            window.open(url, '_blank').focus();
        },
        loadRows(loader = true) {
            if (loader) {
                this.tableLoading = true;
            }

            let url;
            if (this.isCompanyFile) {
                url = `/api/company_files/list/${this.company.id}`;
            } else if (this.isEventFile) {
                url = `/api/event_files/list/${this.event.id}`;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw 'Neither company nor event file!';
            }

            return this.$axios
                .get(`${url}?filters=${JSON.stringify(this.filters)}`)
                .then((response) => {
                    this.rows = response.data.rows;
                })
                .finally(() => {
                    if (loader) {
                        this.tableLoading = false;
                    }
                    this.initial = false;
                });
        },
        fileCategorized() {
            this.closeModal(this.modal);
            this.categoryKey = moment().unix();
            this.fileToCategorize = null;
            this.rowsUpdated = moment().unix();
            this.loadCategories();
        },
        editCategories(id) {
            this.fileLoading = true;
            this.$axios
                .get(`/api/${this.fileSpecificURLPath}s/${id}`)
                .then((response) => {
                    this.fileToCategorize = response.data;
                    this.modal = this.openModal(this.$refs.fileCategoryModal);
                })
                .finally(() => {
                    this.fileLoading = false;
                });
        },
        openSummarizeModal(id) {
            this.activeFile = id;
            this.$nextTick(() => {
                this.modal = this.openModal(this.$refs.summarizeModal);
            });
        },
        closeSummarizeModal() {
            this.closeModal(this.modal);
            this.modal = null;
            this.activeFile = null;
        },
        loadCategories() {
            this.categoriesLoading = true;
            return this.$axios
                .get(`/api/${this.fileSpecificURLPath}_categories`)
                .then((response) => {
                    this.fileCategories = response.data['hydra:member'];
                })
                .finally(() => {
                    this.categoriesLoading = false;
                });
        },
    },
};
