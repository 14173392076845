
import {mapGetters} from "vuex";
import ConfirmModal from "../confirm-modal";
import TableActions from "../table-actions";
import SelectCompanies from "../company/select-companies";
import modals from "../../mixins/modals";
import LoadingSpinner from "../loading-spinner";
import EditBaseGroupFields from "../groups/edit-base-group-fields";
import iriPreparation from "../../mixins/iri-preparation";
import addCompanyGroupReasonModal from "./add-company-group-reason-modal";
import SaveButton from "@/components/save-button";
import optionButtonTooltip from "~/mixins/option-button-tooltip";

export default {
    name: "EditCompanyGroupModal",
    components: {
        EditBaseGroupFields,
        LoadingSpinner,
        SelectCompanies,
        // SearchSelect,
        TableActions,
        ConfirmModal,
        SaveButton,
        addCompanyGroupReasonModal
    },
    mixins: [optionButtonTooltip, modals, iriPreparation],
    props: {
        companyGroupId: {
            type: Number,
            default: null,
        },
        allowDelete: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            eventCollapse: false,
            companiesUpdated: new Date(),
            companyGroupWorkingCopy: null,
            saving: false,
            saved: false,
            eventToAdd: null,
            collapseShown: false,
            companyToAdd: null,
            companySaving: false,
            companyToRemove: null,
            confirmOpen: false,
            selectedCompanyIds: [],
            originalCompanies: [],
            companiesManipulated: false,
            initialLoad: true,
            ajaxAll: 0,
            ajaxRows: null,
            pageChanged: false,
            preSelectedRows: [],
            preSelectedRowIds: [],
            selectedRows: [],
            searchQuery: "",
            multipleCompaniesToRemove: null,
            loading: false,
            duplicateGroups: null,
            config: {
                search: true,
                pagination: 10,
                select: true,
                selectPosition: "pre",
                selectAll: true,
                prettySelect: true,
                pageSortSelect: true,
                columns: [
                    {
                        headline: "Company",
                        sort: true,
                    },
                    {
                        headline: "Ticker",
                        sort: true,
                    },
                    {
                        headline: "Reason",
                        sort: true,
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                    },
                ]
            },
            withoutGroup: false,
            reason: null,
            cgcIdsToAddReason: null,
            companyReason: "",
            addReasons: false,
        }
    },

    computed: {
        ...mapGetters({
            currentRoute: "app/currentRoute"
        }),

        companyParams() {
            const params = {};
            if (this.withoutGroup) {
                params.without_group = true
            } else if (this.companyGroupWorkingCopy && this.companyGroupWorkingCopy.id) {
                params.exclude_groups = this.companyGroupWorkingCopy.id
            }
            return params;
        },

        isNew() {
            return !this.companyGroupWorkingCopy || !this.companyGroupWorkingCopy.id
        },
        milliseconds() {
            return this.companiesUpdated.getUTCMilliseconds();
        },
        companyGroupIdForLinks() {
            if (this.companyGroupWorkingCopy) {
                return this.companyGroupWorkingCopy.id;
            } else if (this.companyGroupId) {
                return this.companyGroupId;
            } else {
                return null;
            }
        }
    },
    watch: {
        companyToAdd(val) {
            if (val) {
                if (!val['@id'] || this.addReasons) {
                    return
                }

                this.addCompany();
            } else {
                this.$formulate.reset("add-company-form")
            }
        },
        selectedRows(rows) {
            if (!this.pageChanged && this.ajaxRows) {

                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedRowIds.includes(rows[i].cgc_id)) {
                        this.preSelectedRowIds.push(rows[i].cgc_id);
                    }
                }

                for (let j = 0; j < this.ajaxRows.length; j++) {
                    const id = this.ajaxRows[j].cgc_id;

                    const index = this.preSelectedRowIds.indexOf(id);

                    if (!rows.map(r => r.cgc_id).includes(id) && index !== -1) {

                        this.preSelectedRowIds.splice(index, 1);
                    }
                }
            }

        },
        isNew: {
            handler(bool) {
                if (!bool) {
                    this.$set(this.config, "ajaxUrl", `/api/company_groups/${this.companyGroupWorkingCopy.id}/companies`)
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.$refs.editCompanyGroupModal.addEventListener('hidden.bs.modal', this.onHideModal)

        this.$refs.editCompanyGroupModal.addEventListener('shown.bs.modal', this.onShowModal)

        this.$refs.removeCompanyFromGroupModal.$el.addEventListener('show.bs.modal', () => {
            this.$refs.editCompanyGroupModal.removeEventListener('hidden.bs.modal', this.onHideModal)
            this.$refs.editCompanyGroupModal.removeEventListener('shown.bs.modal', this.onShowModal)
            this.confirmOpen = true;
        })
        this.$refs.removeCompanyFromGroupModal.$el.addEventListener('hide.bs.modal', () => {
            this.confirmOpen = false;
        })
        this.$refs.removeCompanyFromGroupModal.$el.addEventListener('hidden.bs.modal', () => {
            this.$nextTick(() => {
                this.$refs.editCompanyGroupModal.addEventListener('hidden.bs.modal', this.onHideModal)
                this.$refs.editCompanyGroupModal.addEventListener('shown.bs.modal', this.onShowModal)
            })
        })


        this.$refs.removeMultipleCompaniesFromGroupModal.$el.addEventListener('show.bs.modal', () => {
            this.$refs.editCompanyGroupModal.removeEventListener('hidden.bs.modal', this.onHideModal)
            this.$refs.editCompanyGroupModal.removeEventListener('shown.bs.modal', this.onShowModal)
            this.confirmOpen = true;
        })
        this.$refs.removeMultipleCompaniesFromGroupModal.$el.addEventListener('hide.bs.modal', () => {
            this.confirmOpen = false;
        })
        this.$refs.removeMultipleCompaniesFromGroupModal.$el.addEventListener('hidden.bs.modal', () => {
            this.$nextTick(() => {
                this.$refs.editCompanyGroupModal.addEventListener('hidden.bs.modal', this.onHideModal)
                this.$refs.editCompanyGroupModal.addEventListener('shown.bs.modal', this.onShowModal)
            })
        })

        this.$refs.collapse.addEventListener('show.bs.collapse', () => {
            this.collapseShown = true;
        })
        this.$refs.collapse.addEventListener('hide.bs.collapse', () => {
            this.collapseShown = false;
        })
    },
    methods: {
        onShowModal() {
            if (this.companyGroupId) {
                this.loadCompanyGroup();
            } else {
                // Here we draft a new company groups. New fields needs to be added here!
                this.companyGroupWorkingCopy = {
                    title: "",
                    description: "",
                    isPrivate: false,
                    creatingUser: `/api/users/${this.userInfo.id}`,
                    isTemporary: false,
                    sharedWithUsers: [],
                    companyGroupEvents: [],
                }
            }
        },
        onHideModal() {
            if (this.saved) {
                this.$emit('company-group-updated')
            } else if (this.companiesManipulated) {
                this.revert(this.companyGroupWorkingCopy.id);
            }

            this.companyGroupWorkingCopy = null;
            this.saved = false;
            this.companiesManipulated = false;
            this.initialLoad = true;
        },
        onRowsUpdated(data) {
            this.ajaxRows = data.rows;
            setTimeout(() => this.initializeOptionButtonTooltips('rows updated'), 250);

            this.pageChanged = false;
            if (data && data.rows && data.rows.length) {
                this.preSelectedRows = JSON.parse(JSON.stringify(this.preSelectedRowIds)).map(id => {
                    return {
                        key: "cgc_id",
                        value: id
                    }
                });
            }
            this.ajaxAll = data.all;
        },
        removeCompany(id) {
            this.companyToRemove = id;
            this.modal = this.openModal(this.$refs.removeCompanyFromGroupModal);
        },
        doRemoveCompany(id) {
            this.loading = true;

            this.$axios.delete("/api/company_group_companies/" + id).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Removed",
                    message: "Company removed from group",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                    this.loadCompanyGroup();
                })
                this.companiesManipulated = true;
            }).finally(() => {
                this.companiesUpdated = new Date()
                this.companyToRemove = null;
                this.loading = false;
            });
            this.doSave(true)
        },
        cancelRemoveCompany() {
            this.companyToRemove = null
        },
        loadCompanyGroup() {

            const companyGroupId = this.companyGroupId ?? this.companyGroupWorkingCopy.id;

            this.$axios.get(`/api/company_groups/${companyGroupId}`).then((response) => {
                this.companyGroupWorkingCopy = JSON.parse(JSON.stringify(response.data))
                if (this.initialLoad) {
                    this.initialLoad = false;
                    this.originalCompanies = JSON.parse(JSON.stringify(response.data.companyGroupCompanies));
                }
            })
        },
        save() {
            this.$nextTick(() => {
                this.$formulate.submit("company-group-form");
            })
        },
        revert(id) {
            this.saving = true;

            const removed = [];

            this.$axios.get(`/api/company_group_companies?companyGroup=${id}&groups[]=company_group_company:blank`).then((response) => {

                const companies = JSON.parse(JSON.stringify(response.data["hydra:member"].map(x => x["@id"])));

                // re-add removed companies
                this.originalCompanies.forEach((cgc) => {
                    if (!companies.includes(cgc["@id"])) {
                        removed.push({
                            company: cgc.company["@id"],
                            companyGroup: `/api/company_groups/${id}`
                        })
                    }
                })

                // remove added companies
                const originalIds = this.originalCompanies.map(cgc => cgc["@id"]);
                const before = companies.filter(x => originalIds.includes(x));

                if (removed.length || companies.length > before.length) {
                    this.$axios.patch(`/api/company_groups/${id}`, {companyGroupCompanies: [...before, ...removed]}, {
                        headers: {
                            'Content-Type': 'application/merge-patch+json'
                        }
                    }).finally(() => {
                        this.saving = false;
                    })
                }
            })
        },
        doSave(addCompany) {
            this.saving = true;


            // Prepare the contact group object
            this.companyGroupWorkingCopy = this.prepareIri(this.companyGroupWorkingCopy);
            // Prepare the sharedWithUsers => IRIs only
            this.companyGroupWorkingCopy.sharedWithUsers = this.prepareIri(this.companyGroupWorkingCopy.sharedWithUsers);
            if (this.isNew) {

                const companies = [];

                this.selectedCompanyIds.forEach((id) => {
                    companies.push({
                        company: `/api/companies/${id}`,
                        reason: this.reason
                    })
                })
                const promise = Promise.resolve(null);


                promise.catch(() => {
                    this.saving = false;
                })


                promise.then(() => {

                    this.companyGroupWorkingCopy.companyGroupCompanies = companies;

                    if (this.companyGroupWorkingCopy.isTemporary === "") {
                        console.warn("I did switch it to false!");
                        this.companyGroupWorkingCopy.isTemporary = false;
                    }
                    const events = this.companyGroupWorkingCopy.companyGroupEvents;
                    if (events) {
                        this.companyGroupWorkingCopy.companyGroupEvents = events.map(event => event['@id']);
                    }
                    this.$axios.post("/api/company_groups", this.companyGroupWorkingCopy).then((response) => {
                        this.companyGroupWorkingCopy = JSON.parse(JSON.stringify(response.data));

                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Saved",
                            message: "Group added",
                            id: toastId,
                        })


                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        })
                    }).finally(() => {
                        this.saving = false;
                        this.saved = true;
                    })
                })
            } else {
                this.$delete(this.companyGroupWorkingCopy, "companyGroupHistories");
                this.$delete(this.companyGroupWorkingCopy, "companyGroupCompanies");
                const events = this.companyGroupWorkingCopy.companyGroupEvents;
                if (events) {
                    this.companyGroupWorkingCopy.companyGroupEvents = events.map(event => event['@id']);
                }
                this.$axios.patch(`/api/company_groups/${this.companyGroupWorkingCopy.id}`, this.companyGroupWorkingCopy, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                }).then((response) => {
                    this.companyGroupWorkingCopy = JSON.parse(JSON.stringify(response.data));

                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Saved",
                        message: "Group updated",
                        id: toastId,
                    })
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                    if (!addCompany) {
                        this.$emit("saved");
                    }

                }).finally(() => {
                    this.saving = false;
                    this.saved = true;
                })
            }
        },
        clearPreSelection() {
            this.preSelectedRowIds = [];
            this.preSelectedRows = [];
        },

        removeMultipleCompanies(mode) {
            this.multipleCompaniesToRemove = mode;
            this.modal = this.openModal(this.$refs.removeMultipleCompaniesFromGroupModal);

        },
        doRemoveMultipleCompanies(mode) {
            this.loading = true;

            let promise;

            if (mode === "selected" || mode === "page") {
                let idsToRemove;
                switch (mode) {
                    case "selected":
                        idsToRemove = JSON.parse(JSON.stringify(this.preSelectedRowIds)).map(x => `/api/company_group_companies/${x}`)
                        break;

                    case "page":
                        idsToRemove = this.ajaxRows.map(function (row) {
                            return `/api/company_group_companies/${row.cgc_id}`;
                        })
                        break;
                }

                promise = new Promise(resolve => {
                    this.$axios.get(`api/company_groups/${this.companyGroupWorkingCopy.id}`).then((response) => {
                        const companyGroup = response.data;

                        const companyGroupCompanies = [];

                        companyGroup.companyGroupCompanies.forEach((cgc) => {
                            if (!idsToRemove.includes(cgc["@id"])) {
                                companyGroupCompanies.push(cgc["@id"]);
                            }
                        })

                        this.$axios.patch(`/api/company_groups/${companyGroup.id}`, {
                            companyGroupCompanies,
                        }, {
                            headers: {
                                'Content-Type': 'application/merge-patch+json'
                            }
                        }).finally(() => {
                            resolve();
                        });

                    })
                });

            } else if (mode === "all") {
                // remove all
                promise = this.$axios.patch(`/api/company_groups/${this.companyGroupWorkingCopy.id}`, {companyGroupCompanies: []}, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                })
            }

            promise.then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Removed",
                    message: "Companies removed from group",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                    this.loadCompanyGroup();
                })
                this.companiesManipulated = true;
            }).finally(() => {
                this.companiesUpdated = new Date()
                this.multipleCompaniesToRemove = null;
                this.loading = false;
            });
            this.doSave(true)
        },
        cancelRemoveMultipleCompanies() {
            this.multipleCompaniesToRemove = null;
        },
        updateSearch(query) {
            this.searchQuery = query;
            this.pageChanged = true;
        },
        onPageChange() {
            this.pageChanged = true;
        },
        setReason(reason) {
            this.reason = reason
        },
        async addReason(mode) {
            if (mode === 'multiple') {
                this.cgcIdsToAddReason = JSON.parse(JSON.stringify(this.preSelectedRowIds))

            } else {
                this.cgcIdsToAddReason = [mode]
            }

            await this.$nextTick();
            this.$refs.addCompanyReasonModal.$el.addEventListener('show.bs.modal', () => {
                this.$refs.editCompanyGroupModal.removeEventListener('hidden.bs.modal', this.onHideModal)
                this.$refs.editCompanyGroupModal.removeEventListener('shown.bs.modal', this.onShowModal)
                this.confirmOpen = true;
            })
            this.$refs.addCompanyReasonModal.$el.addEventListener('hide.bs.modal', () => {
                this.confirmOpen = false;
            })
            this.$refs.addCompanyReasonModal.$el.addEventListener('hidden.bs.modal', () => {
                this.$nextTick(() => {
                    this.$refs.editCompanyGroupModal.addEventListener('hidden.bs.modal', this.onHideModal)
                    this.$refs.editCompanyGroupModal.addEventListener('shown.bs.modal', this.onShowModal)
                })
            })
            this.modal = this.openModal(this.$refs.addCompanyReasonModal);
        },
        cgcUpdated() {
            this.closeModal(this.modal);
            this.companiesUpdated = new Date();
            this.cgcIdsToAddReason = null;
        },
        addCompany() {
            this.companySaving = true;

            this.$axios.post('/api/company_group_companies', {
                company: this.companyToAdd['@id'],
                reason: this.companyReason,
                companyGroup: `/api/company_groups/${this.companyGroupWorkingCopy.id}`
            }).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Added",
                    message: "Company added to group",
                    id: toastId,
                })
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                    this.companySaving = false;
                    this.companyToAdd = null;
                    this.companyReason = "";
                    this.companiesUpdated = new Date()
                    this.companiesManipulated = true;
                    this.loadCompanyGroup();
                })
            })
            this.doSave(true)
        },
        updateDuplicateWarning(val) {
            if (!this.isNew || String(val).length < 3) {
                this.duplicateGroups = [];
                return;
            }
            this.$axios.get(`/api/company_groups/list?search=${val}`)
                .then((response) => {
                    if (response.data && response.data.all > 0) {
                        this.duplicateGroups = response.data.rows;
                    } else {
                        this.duplicateGroups = null;
                    }
                });
        },
        deleteCompanyGroup() {
            this.modal = this.openModal(this.$refs.deleteCompanyGroupModal);
        },
        doDeleteCompanyGroup(id) {
            this.companyGroupWorkingCopy = null
            this.$emit("delete", id)
        },
    },

}
