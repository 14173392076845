
import SaveButton from '../../save-button.vue';
import ModalHeader from '../../modal-header.vue';
import LoadingSpinner from '../../loading-spinner.vue';

export default {
    name: 'SummarizeModal',
    components: {
        LoadingSpinner,
        SaveButton,
        ModalHeader,
    },
    props: {
        fileId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            summary: '',
            showGenerateSummary: false,
            extraInstructions: '',
        };
    },
    mounted() {
        this.loading = true;

        // add close handler
        this.$refs.modal.addEventListener('hidden.bs.modal', () => {
            this.$emit('close');
        });

        // load the file data
        this.$axios
            .get(`/api/company_files/${this.fileId}`)
            .then((response) => {
                this.summary = response.data.summaryText ?? '';

                if (!this.summary) {
                    this.showGenerateSummary = true;
                }
            })
            .finally(() => {
                this.loading = false;
            });
    },
    methods: {
        generateSummary() {
            this.loading = true;

            this.$axios
                .post(`/api/company_files/${this.fileId}/summarize`, {
                    instructions: this.extraInstructions,
                })
                .then(({ data }) => {
                    this.summary = data;
                    this.showGenerateSummary = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        saveSummary() {
            this.loading = true;

            this.$axios
                .put(`/api/company_files/${this.fileId}`, {
                    summaryText: this.summary,
                })
                .then((response) => {
                    console.log('summarize', response);
                })
                .then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: 'success',
                        title: 'Success',
                        message: 'The summary was updated successfully.',
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                })
                .finally(() => {
                    this.$emit('close');
                });
        },
    },
};
