import { render, staticRenderFns } from "./details-card.vue?vue&type=template&id=1e6437ea&scoped=true"
import script from "./details-card.vue?vue&type=script&lang=js"
export * from "./details-card.vue?vue&type=script&lang=js"
import style0 from "./details-card.vue?vue&type=style&index=0&id=1e6437ea&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e6437ea",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FavStar: require('/codebuild/output/src3357764168/src/web/components/fav-star.vue').default,RedFlag: require('/codebuild/output/src3357764168/src/web/components/red-flag.vue').default,Restricted: require('/codebuild/output/src3357764168/src/web/components/restricted.vue').default,CompanyDataPointQuickEdit: require('/codebuild/output/src3357764168/src/web/components/company/company-data-point-quick-edit.vue').default,GmapsAddress: require('/codebuild/output/src3357764168/src/web/components/gmaps-address.vue').default,InvestorTargetsModal: require('/codebuild/output/src3357764168/src/web/components/investor-targets/investor-targets-modal.vue').default})
